import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Home = ({ data }) => (
  <Layout>
    <Seo
      title="Articles sur la piscine privée"
      lang="en"
      meta={[
        {
          name: 'og:image',
          content: data.authorImage.childImageSharp.resize.src,
        },
      ]}
    />

    <h1 className="mb-8">Le monde de la piscine particulière</h1>

    <GatsbyImage
      image={data.authorImage.childImageSharp.gatsbyImageData}
      alt="Jean-Christophe Baey"
      style={{ margin: '0 auto', display: 'block' }}
    />

    <p className="mt-8">
      Vous contruisez une piscine? vous avez acheté une maison avec une piscine ou vous souhaitez
      moderniser votre installation, vous trouverez toutes les informations liées à la piscine particulière.
    </p>
   
    {/* <Divider /> */}
    <section className="border-gray-500 border-t-2  pt-16 pb-16" id="articles">
      <h1 className="mt-0">Articles</h1>
      <ul className="">
        {data.allMarkdownRemark.nodes.map((post) => (
          <li key={post.id}>
            <h2 className="mb-0">
              <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
            </h2>
            <span className="block font-sans font-normal md:text-xl text-base text-gray-600 my-2">
              {post.frontmatter.subtitle}
            </span>
            <span className="text-gray-500 font-normal font-sans md:text-base text-sm block">
              {post.frontmatter.date} · temps de lecture: {Math.floor(post.fields.readingTime.minutes)} min
            </span>
          </li>
        ))}
      </ul>
    </section>
  </Layout>
);

export default Home;

// import { colors } from '../lib/tailwind-utils';
// const bgColor = colors['gray-100']; // #f7fafc

export const pageQuery = graphql`
  # query($bgColor: String = "#f7fafc") {
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          date(formatString: "D MMMM YYYY", locale: "fr-FR")
          path
          title
          author
          subtitle
        }
        fields {
          readingTime {
            text
            minutes
          }
        }
      }
    }
    authorImage: file(relativePath: { eq: "images/home.jpg" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 400, placeholder: TRACED_SVG)
        resize(width: 1200, height: 628) {
          src
        }
      }
    }
  }
`;

console.log('pageQuery', pageQuery);
